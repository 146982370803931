import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  // {
  //   path: 'account',
  //   loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  // },
  {
    path: 'sourcing',
    loadChildren: () => import('./sourcing/sourcing.module').then( m => m.SourcingPageModule)
  },
  // {
  //   path: 'inspection',
  //   loadChildren: () => import('./inspection/inspection.module').then( m => m.InspectionPageModule)
  // },
  {
    path: 'account-verification',
    loadChildren: () => import('./account-verification/account-verification.module').then( m => m.AccountVerificationPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('./purchase/purchase.module').then( m => m.PurchasePageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: 'mycart',
    loadChildren: () => import('./mycart/mycart.module').then( m => m.MycartPageModule)
  },{
    path: 'workflow',
    loadChildren: () => import('./mychat/mychat.module').then( m => m.MychatPageModule)
  },
  {
    path: 'sourcing-results',
    loadChildren: () => import('./sourcing/results/results.module').then( m => m.ResultsPageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  // {
  //   path: 'myorder',
  //   loadChildren: () => import('./myorder/myorder.module').then( m => m.MyorderPageModule)
  // },
  {
    path: 'myorderdetail',
    loadChildren: () => import('./myorder/myorderdetail/myorderdetail.module').then( m => m.MyorderdetailPageModule)
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'workflow-task',
    loadChildren: () => import('./workflow-task/workflow-task.module').then( m => m.WorkflowTaskPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
